<template>
    <div class="layout">
        <Layout>
            <router-view></router-view>
        </Layout>
    </div>
</template>

<script>
  // import * as mx from '../mixins'

  export default {
    name: "layout",
    // mixins: [mx.nowLang],
    data() {
        return {
            navigation: this.$store.state.navigation,
            isCollapsed: false,
            timer: null,
        }
    },
    filters: {
        openName: function (v) {
            if (v.split('/')[1]) {
                return ['/' + v.split('/')[1]]
            } else {
                return [v]
            }
        }
    },
    computed: {
        rotateIcon() {
            return [
                'menu-icon',
                this.isCollapsed ? 'rotate-icon' : ''
            ];
        },
        menuitemClasses() {
            return [
                'menu-item',
                this.isCollapsed ? 'collapsed-menu' : ''
            ]
        }
    },
    methods: {
        changeLang: function () {
            // 切换语言
            this.$util.changeLang(this)
        },
        collapsedSider() {
            this.$refs.navigation.toggleCollapse();
        },
        getMesg() {
            let _this = this
            this.$store.dispatch('GET_UNREAD_COUNT')
            this.timer = setInterval(function() {
                _this.$store.dispatch('GET_UNREAD_COUNT')
            }, 1000*60)
        },
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    mounted() {
        this.getMesg()
    }
  }
</script>

<style lang="less" module>
@import '~less/color';
.primary {
    color: @primary-color;
}
</style>
<style lang="less">
@import '~less/color';


.rotate-icon{
    transform: rotate(-90deg);
    transition: all .3s;
}
.menu-icon{
    transition: all .3s;
} 
.ivu-layout-sider-children{
    overflow-x: hidden;
}
.ivu-layout-sider {
    overflow: hidden;
}

.layout-nav {
    width: 500px;
    margin: 0 auto;
    margin-right: 20px;
}
.overflow{
    overflow-y: auto;
    position: relative;
    padding: 20px 47px 0px;
    min-width: 1186px;
    height: 100%;
    background: #f2f9ff url('~assets/layout_bak.png') 80% 0px no-repeat;
}
.wrapper {
    overflow: auto !important;
    width: 100%;
    background: #f2f9ff;
    .layout-content {
        overflow-x: auto;
        width: 100%;
        height: calc(100% - 64px);
    }
}
</style>